<template>
  <v-container class="my-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col
        v-if="!$vuetify.breakpoint.mobile"
        cols="12"
        sm="3"
        class="my-0 py-0 px-2"
      >
        <v-card
          :disabled="isloading"
          class="pa-2 my-0 mx-2 rounded-lg dborder"
          flat
          style="min-width: 20vw"
        >
          <div
            @click="tab = 0"
            class="
              mb-1
              rounded
              d-flex
              align-center
              px-3
              font-weight-medium
              text-uppercase
            "
            :class="tab === 0 ? 'yellow darken-3 white--text' : 'primary--text'"
            style="height: 36px; cursor: pointer"
            :style="tab === 0 ? '' : 'background-color: rgb(242, 247, 253);'"
          >
            {{ $t("SCH.avail") }}
          </div>
          <div
            @click="tab = 1"
            class="
              mb-1
              rounded
              d-flex
              align-center
              px-3
              font-weight-medium
              text-uppercase
            "
            :class="tab === 1 ? 'yellow darken-3 white--text' : 'primary--text'"
            style="height: 36px; cursor: pointer"
            :style="tab === 1 ? '' : 'background-color: rgb(242, 247, 253);'"
          >
            {{ $t("SCH.days_off") }}
          </div>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        :class="
          $vuetify.breakpoint.mobile
            ? 'ma-0 pa-0 overflow-x-hidden'
            : 'my-0 py-0'
        "
      >
        <v-expand-transition>
          <v-card
            class="transparent pa-0 ma-0"
            flat
            v-show="$vuetify.breakpoint.mobile"
          >
            <v-btn-toggle
              v-model="tab"
              mandatory
              borderless
              dense
              style="width: 100%"
              class="mx-0 mb-2 blue primary--text rounded-lg"
            >
              <v-btn active-class="activebtn" width="50%" class="primary--text">
                {{ $t("SCH.avail") }}
              </v-btn>
              <v-btn active-class="activebtn" width="50%" class="primary--text">
                {{ $t("SCH.days_off") }}
              </v-btn>
            </v-btn-toggle>
          </v-card>
        </v-expand-transition>
        <template v-if="tab === 0">
          <v-tabs
            show-arrows=""
            v-model="day"
            center-active
            grow
            class="dborder rounded-lg"
          >
            <v-tab v-for="(day, index) in days" :key="index">{{ day }}</v-tab>
          </v-tabs>
          <div
            class="mt-2 overflow-y-auto hidescroll"
            style=" overscroll-behavior: contain;"
            :style="
              $vuetify.breakpoint.mobile
                ? 'height:calc(100vh - 124px - 105px);'
                : 'height:calc(100vh - 180px);'
            "
          >
            <div>
              <v-menu
                v-model="showdeleteoption"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
                style="max-width: 600px"
              >
                <v-btn @click="deleteAlert('shift')" class="red--text">
                  <unicon
                    style="margin-right: auto; margin-left: auto"
                    name="trash-alt"
                    fill="#f64e60"
                    height="20"
                  ></unicon>
                  Delete Shift</v-btn
                >
              </v-menu>
              <div>
                <div
                  v-for="(item, index) in AllHours[this.day]"
                  :key="item.time"
                  class="d-flex"
                >
                  <div
                    :style="
                      $vuetify.breakpoint.mobile && item.iszerohour
                        ? 'min-width:7vw; border-top: solid 1px #d5d5d5;'
                        : $vuetify.breakpoint.mobile && !item.iszerohour
                        ? 'min-width:7vw'
                        : !$vuetify.breakpoint.mobile && item.iszerohour
                        ? 'min-width:2vw; border-top: solid 1px #d5d5d5;'
                        : 'min-width:2vw'
                    "
                    style=""
                  >
                    <span
                      v-show="item.iszerohour"
                      class="primary--text font-weight-bold px-1"
                    >
                      {{ $util.day.unix(item.time).format("hh") }}</span
                    >
                  </div>
                  <div
                    :style="
                      item.iszerohour
                        ? 'border-top: solid 1px #d5d5d5;'
                        : 'border-top: solid 1px #e5e5e5;'
                    "
                    @click="handleSelection(index, $event)"
                    :id="`booked-${index}`"
                    :class="
                      item.isBooked
                        ? 'yellow darken-3 white--text '
                        : item.isHeightlighted
                        ? 'blue lighten-4 primary--text'
                        : 'primary--text'
                    "
                    class="flex-grow-1 px-1 d-flex"
                  >
                    <div
                      :style="
                        $vuetify.breakpoint.mobile
                          ? 'min-width:5vw'
                          : 'min-width:1vw'
                      "
                    >
                      {{ $util.day.unix(item.time).format("mm") }}
                    </div>
                    {{ $util.day.unix(item.time).format("A") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <v-list-item
            class="listitem mb-1"
            v-for="day in daysoff"
            :key="day.id"
          >
            <v-list-item-content>
              <v-list-item-title class="text-h6 primary--text">
                {{ $util.day(`${day.date}`).format(" DD/MM/YYYY - dddd") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action
              v-if="$util.day().isBefore($util.day(`${day.date}`))"
            >
              <v-btn icon elevation="0" @click="deleteAlert('dayoff', day.id)">
                <unicon
                  style="margin-right: auto; margin-left: auto"
                  name="trash-alt"
                  fill="#f64e60"
                  height="20"
                ></unicon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-col>
    </v-row>

    <v-scroll-y-reverse-transition>
      <v-btn
        @click="dayoffdialog = true"
        v-show="tab === 1"
        :style="$root.lang === 'ar' ? 'left: 10px;' : 'right: 10px;'"
        style="
          z-index: 1;
          position: fixed;
          bottom: calc(env(Safe-area-inset-bottom, 40px) + 30px);
        "
        fab
        elevation="0"
        color="primary"
        dark
      >
        <v-icon ref="addicon" class="" color="white">mdi-plus</v-icon>
      </v-btn>
    </v-scroll-y-reverse-transition>
    <v-dialog v-model="confirmdialog" max-width="290" persistent>
      <v-card
        :loading="isconfirming"
        :disabled="isconfirming"
        class="rounded-lg"
      >
        <v-card-title class="text-h5">
          {{ $t("SCH.create") }}
        </v-card-title>

        <v-card-text
          v-if="startedAt !== -1 && endAt !== -1"
          style="font-size: 1.1rem"
        >
          {{
            $t("SCH.create_body", {
              time:
                endAt > startedAt
                  ? `${$util.day
                      .unix(AllHours[day][startedAt].time)
                      .format("hh:mm A")} to ${$util.day
                      .unix(AllHours[day][endAt].time)
                      .format("hh:mm A")}`
                  : `${$util.day
                      .unix(AllHours[day][endAt].time)
                      .format("hh:mm A")} to ${$util.day
                      .unix(AllHours[day][startedAt].time)
                      .format("hh:mm A")}`
            })
          }}
        </v-card-text>

        <v-card-actions style="border-top: solid 1px lightgray" class="pa-0">
          <v-row style="width: 100%" class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-btn
                color="red darken-1"
                text
                class="elevation-0"
                block
                large
                @click="
                  isconfirming = false;
                  confirmdialog = false;

                  resetday();
                "
              >
                {{ $t("GENERAL.cancel") }}
              </v-btn>
            </v-col>
            <v-col
              class="pa-0 ma-0"
              :style="
                $root.lang === 'ar'
                  ? 'border-right: solid 1px lightgray'
                  : 'border-left: solid 1px lightgray'
              "
            >
              <v-btn
                color="primary darken-1"
                class="elevation-0"
                text
                block
                large
                @click="createItem()"
              >
                {{ $t("AUTH.REG_STEPS.actions.confirm") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletedialg" max-width="290" persistent>
      <v-card :loading="isdeleting" :disabled="isdeleting" class="rounded-lg">
        <v-card-title class="text-h5">
          {{ deleteTitle }}
        </v-card-title>

        <v-card-text style="font-size: 1.1rem">
          {{ deleteBody }}
        </v-card-text>

        <v-card-actions style="border-top: solid 1px lightgray" class="pa-0">
          <v-row style="width: 100%" class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-btn
                color="primary darken-1"
                text
                class="elevation-0"
                block
                large
                @click="cancelDelete()"
              >
                {{ $t("GENERAL.cancel") }}
              </v-btn>
            </v-col>
            <v-col
              class="pa-0 ma-0"
              :style="
                $root.lang === 'ar'
                  ? 'border-right: solid 1px lightgray'
                  : 'border-left: solid 1px lightgray'
              "
            >
              <v-btn
                color="red darken-1"
                class="elevation-0"
                text
                block
                large
                @click="deleteItem()"
              >
                {{ $t("GENERAL.delete") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dayoffdialog" max-width="400" persistent>
      <v-card
        :loading="isaddingdayoff"
        :disabled="isaddingdayoff"
        class="rounded-lg"
      >
        <v-card-title class="text-h5">
          {{ $t("SCH.excepMoalNew") }}
        </v-card-title>

        <v-date-picker
          v-model="dayoffselected"
          color="primary"
          full-width
          no-title
          :allowed-dates="blockexcepdates"
          :min="new Date().toISOString().substr(0, 10)"
        ></v-date-picker>

        <v-card-actions style="border-top: solid 1px lightgray" class="pa-0">
          <v-row style="width: 100%" class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-btn
                @click="dayoffdialog = false"
                color="red darken-1"
                text
                class="elevation-0"
                block
                large
              >
                {{ $t("GENERAL.cancel") }}
              </v-btn>
            </v-col>
            <v-col
              class="pa-0 ma-0"
              :style="
                $root.lang === 'ar'
                  ? 'border-right: solid 1px lightgray'
                  : 'border-left: solid 1px lightgray'
              "
            >
              <v-btn
                color="primary darken-1"
                class="elevation-0"
                text
                :disabled="dayoffselected === null"
                block
                @click="addDayOff"
                large
              >
                {{ $t("AUTH.REG_STEPS.actions.confirm") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "schedule",
  components: {},
  data() {
    return {
      items: [],
      asseturl: process.env.VUE_APP_api_url + "assets/",
      loadnewpageat: -1,
      page: 1,
      limit: 0,
      isloading: false,
      isloadingsection: true,
      tab: 0,
      days: this.$moment.weekdays(),
      daysen: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
      day: 0,
      showmodal: false,
      todelete: null,
      deletedialg: false,
      isdeleting: false,
      selected: null,
      availability: this.$doctor.getCurrentDoctor().availability,
      daysoff: this.$doctor.getCurrentDoctor().days_off,
      today: new Date().toISOString().substr(0, 10),
      AllHours: [[], [], [], [], [], [], []],
      shifts: [[], [], [], [], [], [], []],
      startselecting: false,
      startedAt: -1,
      endAt: -1,
      rowgroupby: 1,
      isconfirming: false,
      confirmdialog: false,
      showdeleteoption: false,
      x: 0,
      y: 0,
      deleteTitle: "",
      deleteBody: "",
      deleteType: "",
      dayoffdialog: false,
      isaddingdayoff: false,
      dayoffselected: null
    };
  },
  beforeMount() {},
  created() {
    // this.getItems();
  },
  computed: {
    avail() {
      return this.availability.filter(item => {
        return item.days.includes(this.daysen[this.day]);
      });
    }
  },
  watch: {
    day() {
      this.manageBooked();
    }
  },
  mounted() {
    this.$root.title = this.$t("MENU.schedule");
    this.$root.currentpage = this.$route.name;
    let starttime = this.$util.day(
      `${new Date()
        .toISOString()
        .substr(0, 10)
        .toString()}T06:00`
    );
    let endtime = starttime.add(24, "h");

    let itreatator = this.$doctor.getCurrentDoctor().call_duration
      ? this.$doctor.getCurrentDoctor().call_duration
      : this.$util.getSettings().call_duration;
    switch (itreatator) {
      case 15:
        this.rowgroupby = 1;
        break;
      case 30:
        this.rowgroupby = 2;
        break;
      case 45:
        this.rowgroupby = 3;
        break;
      case 60:
        this.rowgroupby = 4;
        break;
      default:
        this.rowgroupby = 1;
    }
    itreatator = itreatator * 60;

    let loopstartat = parseInt(starttime.format("X").toString());
    while (loopstartat < parseInt(endtime.format("X").toString())) {
      this.AllHours[this.day].push({
        time: loopstartat,
        isBooked: false,
        isHeightlighted: false,
        groupid: 0,
        backendID: 0,
        iszerohour:
          this.$util.day
            .unix(loopstartat)
            .format("mm")
            .toString() === "00"
      });
      loopstartat = loopstartat + 900;
    }
    for (let i = 1; i < this.AllHours.length; i++)
      this.AllHours[i] = this.AllHours[0];
    // console.log("all hours", this.AllHours);
    this.manageBooked();
  },
  methods: {
    blockexcepdates(val) {
      // console.log("block exceptions",val)
      return (
        this.daysoff.findIndex(i => {
          return (
            i.date == val || val === new Date().toISOString().substr(0, 10)
          );
        }) == -1
      );
    },
    deleteAlert(type, id) {
      this.deleteType = type;
      if (type === "shift") {
        this.deleteTitle = this.$t("SCH.delete.shift.title");
        this.deleteBody = this.$t("SCH.delete.shift.body");
        this.deletedialg = true;
      } else {
        this.deleteTitle = this.$t("SCH.delete.dayoff.title");
        this.deleteBody = this.$t("SCH.delete.dayoff.body");
        this.todelete = id;
        this.deletedialg = true;
      }
    },
    cancelDelete() {
      this.deleteType = "";
      this.deleteTitle = "";
      this.deleteBody = "";
      this.deletedialg = false;
      this.todelete = null;
      this.isdeleting = false;
    },
    resetday() {
      this.AllHours[this.day].forEach(item => {
        item.isHeightlighted = false;
      });
      this.startedAt = -1;
      this.endAt = -1;
    },
    manageBooked() {
      this.AllHours[this.day].forEach(item => {
        item.isBooked = false;
        item.isHeightlighted = false;
      });
      this.avail.forEach(item => {
        let start = `${new Date()
          .toISOString()
          .substr(0, 10)
          .toString()}T${item.from}Z`;
        let end = `${new Date()
          .toISOString()
          .substr(0, 10)
          .toString()}T${item.to}Z`;
        if (
          this.shifts[this.day].findIndex(shift => {
            return shift.backendID === item.id;
          }) === -1
        ) {
          this.shifts[this.day].push({
            start: this.$util.day(start),
            end: this.$util.day(end),
            backendID: item.id
          });
        }

        let startIndex = this.AllHours[this.day].findIndex(item => {
          return (
            parseInt(
              this.$util
                .day(start)
                .format("X")
                .toString()
            ) === item.time
          );
        });
        let endIndex = this.AllHours[this.day].findIndex(item => {
          return (
            parseInt(
              this.$util
                .day(end)
                .format("X")
                .toString()
            ) === item.time
          );
        });

        for (startIndex; startIndex <= endIndex; startIndex++) {
          this.AllHours[this.day][startIndex].isBooked = true;
          this.AllHours[this.day][startIndex].backendID = item.id;
          this.AllHours[this.day][startIndex].groupid = item.id;
        }
      });
    },
    handleSelection(i, e) {
      if (this.AllHours[this.day][i].isBooked) {
        this.startedAt = i;
        this.x = e.clientX;
        this.y = e.clientY;
        this.showdeleteoption = false;
        this.$nextTick(() => {
          this.showdeleteoption = true;
        });

        return;
      }
      console.log("startedAt", this.startedAt, "i", i);
      if (!this.startselecting) {
        this.startedAt = i;
        this.checkSlot(i);
      } else {
        if (this.startedAt < i) {
          console.log(i, this.startedAt);
          for (let counter = this.startedAt + 1; counter <= i; counter++) {
            this.checkSlot(counter, true);
          }
          this.checkSlot(i);

          let checkedend = i;
          console.log(
            "i is",
            i,
            this.AllHours[this.day],
            this.AllHours[this.day][checkedend]
          );
          while (this.AllHours[this.day][checkedend].isBooked) {
            console.log("time", this.AllHours[this.day][checkedend]);
            checkedend--;
          }
          this.endAt = checkedend;
        } else if (this.startedAt > i) {
          for (let counter = i; counter < this.startedAt; counter++) {
            this.checkSlot(counter, true);
          }
          this.checkSlot(this.startedAt);
          this.endAt = i;
        } else {
          // console.log("ended up here");
          this.AllHours[this.day][i].isHeightlighted = false;
          //this.checkSlot(i);
        }

        // console.log("time after loop");
        if (this.startedAt != i) {
          this.confirmdialog = true;
        }
      }
      this.startselecting = !this.startselecting;
    },
    async checkSlot(i, force = false) {
      this.$nextTick(() => {
        if (this.startselecting || force) {
          // console.log("selecting", this.AllHours[this.day][i]);
          if (!this.AllHours[this.day][i].isBooked) {
            this.AllHours[this.day][i].isHeightlighted = !this.AllHours[
              this.day
            ][i].isHeightlighted;
          } else {
            this.startselecting = false;
          }
        }
      });
    },
    async addDayOff() {
      this.isaddingdayoff = true;
      const payload = {
        date: this.dayoffselected,
        doctor: this.$doctor.getCurrentDoctor().id
      };
      this.$doctor.addDayOff(payload).then(res => {
        if (res.status === 200) {
          this.isaddingdayoff = false;
          this.$doctor.getCurrentDoctor().days_off.push(res.data.data);
          this.$doctor.updateDoctorCache(
            this.$doctor.getCurrentDoctor().doctor_id.id
          );
          this.dayoffselected = null;
          this.dayoffdialog = false;
        } else this.isaddingdayoff = false;
      });
    },
    async createItem() {
      let start =
        this.endAt > this.startedAt
          ? this.AllHours[this.day][this.startedAt].time
          : this.AllHours[this.day][this.endAt].time;
      let end =
        this.endAt > this.startedAt
          ? this.AllHours[this.day][this.endAt].time
          : this.AllHours[this.day][this.startedAt].time;
      const payload = {
        days: [this.daysen[this.day]],
        from: this.$util.day
          .unix(start)
          .utc()
          .format("HH:mm:00"),
        to: this.$util.day
          .unix(end)
          .utc()
          .format("HH:mm:00"),
        doctor: this.$doctor.getCurrentDoctor().id
      };
      console.log("payload", payload);
      this.isconfirming = true;
      this.$doctor.addScheduleShift(payload).then(res => {
        if (res.status === 200) {
          console.log(res.data);
          this.$doctor.getCurrentDoctor().availability.push(res.data.data);
          this.$doctor.updateDoctorCache(
            this.$doctor.getCurrentDoctor().doctor_id.id
          );
          this.manageBooked();
        }

        this.isconfirming = false;
        this.confirmdialog = false;
        this.resetday();
      });
    },
    testdelete() {
      console.log("started at", this.startedAt, "item id to be deleted");
    },
    async deleteItem() {
      // // console.log("item to be deleted", this.todelete);
      this.isdeleting = true;
      if (this.deleteType === "shift") {
        await this.$doctor
          .deleteScheduleShift(
            this.AllHours[this.day][this.startedAt].backendID
          )
          .then(res => {
            if (res.status === 204) {
              // // console.log("delete successful, remove from list", res);
              this.isdeleting = false;
              this.deletedialg = false;
              let index = this.$doctor
                .getCurrentDoctor()
                .availability.findIndex(item => {
                  return (
                    this.AllHours[this.day][this.startedAt].backendID ===
                    item.id
                  );
                });
              this.$doctor.getCurrentDoctor().availability.splice(index, 1);
              this.$doctor.updateDoctorCache(
                this.$doctor.getCurrentDoctor().doctor_id.id
              );
              this.manageBooked();
              this.cancelDelete();
            } else {
              // // console.log("delete error");
              this.isdeleting = false;
            }
          });
      } else {
        await this.$doctor.deleteDayOff(this.todelete).then(res => {
          if (res.status === 204) {
            // // console.log("delete successful, remove from list", res);
            this.isdeleting = false;
            this.deletedialg = false;
            let index = this.$doctor
              .getCurrentDoctor()
              .days_off.findIndex(item => {
                return this.todelete === item.id;
              });
            this.$doctor.getCurrentDoctor().days_off.splice(index, 1);
            this.$doctor.updateDoctorCache(
              this.$doctor.getCurrentDoctor().doctor_id.id
            );
            this.cancelDelete();
          } else {
            // // console.log("delete error");
            this.isdeleting = false;
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}
.activebtn {
  border-style: solid;
  border-color: #d2e1f2 !important;
  background: white !important;
  border-width: 4px !important;
  color: #0971ce !important;
}
</style>
<style scoped>
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) >>> .v-btn.v-btn {
  border-color: #d2e1f2 !important;
  border-width: 0px;
}

.v-btn:before {
  background-color: transparent !important;
}
</style>
